
import { Dealer, dealersApi } from "@/api/dealers";
import { partitionsApi, PartitionSummary } from "@/api/partitions";
import DataTable from "@/app/components/DataTable.vue";
import { searchFilter } from "@/app/searchFilter";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            partitions: new Map<string, PartitionSummary>(),
            search: "",
            items: [] as Dealer[],
            loading: true,
        };
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("Code"),
                    sortable: false,
                    width: "10%",
                },
                {
                    text: this.$t("Name"),
                    sortable: false,
                    width: "30%",
                },
                {
                    text: this.$t("Händlergruppe"),
                    sortable: false,
                    width: "30%",
                },
                {
                    text: this.$t("Zielrufnummern"),
                    sortable: false,
                    width: "30%",
                },
            ];
        },
    },

    methods: {
        async loadItems() {
            this.loading = true;
            try {
                const partitions = (await partitionsApi.list()).filter((p) => p.active);
                for (const partition of partitions) {
                    this.partitions.set(partition.id, partition);
                }
                this.items = (await dealersApi.list())
                    .filter((d) => d.active)
                    .filter((d) => this.partitions.has(d.partitionId));
            } finally {
                this.loading = false;
            }
        },

        filter(search: string | null, item: Dealer) {
            return searchFilter(search, this.partitions.get(item.partitionId)!.name, item.name, item.searchKey);
        },
    },

    async mounted() {
        await this.loadItems();
    },

    components: {
        DataTable,
    },
});
