
import PartitionPanel from "./PartitionPanel.vue";
import { ElasticCluster, elasticClustersApi } from "@/api/elasticClusters";
import { BadRequest } from "@/api/errors";
import { Loadbalancer, loadbalancersApi } from "@/api/loadbalancers";
import { mysqlDatabasesApi, MysqlDatabaseResult } from "@/api/mysqlDatabases";
import { PartitionMachineType, partitionsApi, PartitionSummary } from "@/api/partitions";
import { Permission } from "@/api/userSession";
import CountryPicker from "@/app/components/CountryPicker.vue";
import NumberField from "@/app/components/NumberField.vue";
import { showConfirm, showInfo } from "@/app/messageUtil";
import {
    MAX_PARTITIONS_PER_DATABASE,
    MAX_PARTITIONS_PER_ELASTIC_CLUSTER,
    MAX_PARTITIONS_PER_LOADBALANCER,
} from "@/app/resourceLimits";
import { searchFilter } from "@/app/searchFilter";
import { notEmpty, regEx, ValidationHelper } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import { SelectOption } from "@/util/types";
import Vue from "vue";

enum PartitionsOverviewState {
    LOADING,
    READY,
}

enum PartitionCreatorState {
    HIDDEN,
    LOADING,
    VISIBLE,
    CREATING,
}

export default Vue.extend({
    data() {
        const validationHelper = new ValidationHelper();
        return {
            PartitionsOverviewState,
            partitionsOverviewState: PartitionsOverviewState.READY,
            search: "",
            partitions: [] as PartitionSummary[],
            PartitionCreatorState,
            partitionCreatorState: PartitionCreatorState.HIDDEN,
            loadbalancers: [] as Loadbalancer[],
            mysqlDatabases: [] as MysqlDatabaseResult[],
            elasticClusters: [] as ElasticCluster[],
            valid: true,
            partitionId: "",
            partitionIdRules: regEx(/^(?=.{1,25}$)[a-z][a-z0-9]*(-[a-z0-9]+)*$/)
                .msg(() => this.$t("Gültiger Schlüssel ist erforderlich"))
                .and(validationHelper, "id"),
            prefix: "",
            prefixRules: regEx(/^[A-Z]{1,5}$/)
                .msg(() => this.$t("Gültiges Präfix ist erforderlich"))
                .and(validationHelper, "prefix"),
            sequenceOffset: 0,
            sequenceOffsetRules: notEmpty().integer(),
            country: "",
            currency: "",
            currencyRules: regEx(/^([A-Z]{3})?$/).msg(() => this.$t("Gültiges Währungskürzel ist erforderlich")),
            partitionName: "",
            partitionNameRules: notEmpty()
                .maxLength(255)
                .msg(() => this.$t("Gültiger Name ist erforderlich")),
            validationHelper,
            machineType: (null as unknown) as PartitionMachineType,
            loadbalancer: 0,
            database: "",
            elasticClusterId: 0,
            dealerdeskTwilioUseMainAccount: false,
            marketingEmailsEnabled: true,
            recreatingInstances: false,
        };
    },

    computed: {
        canCreatePartition(): boolean {
            return userSession.hasPermission(Permission.CT_CREATE_PARTITIONS);
        },

        canRenewMysqlPassword(): boolean {
            return this.canCreatePartition && userSession.username === "root";
        },

        canStartPartition(): boolean {
            return userSession.hasPermission(Permission.CT_START_PARTITIONS);
        },

        mysqlDatabaseOptions(): SelectOption[] {
            return this.mysqlDatabases.map((d) => ({
                text: d.mysqlDatabaseSummary.id,
                value: d.mysqlDatabaseSummary.id,
            }));
        },

        partitionMachineTypes() {
            return Object.keys(PartitionMachineType);
        },

        partitionIdSuffix() {
            return `.${configStore.configuration.primaryDomain}`;
        },
    },

    components: {
        PartitionPanel,
        NumberField,
        CountryPicker,
    },

    methods: {
        filter(search: string | null, item: PartitionSummary) {
            return searchFilter(search, item.name);
        },

        async loadPartitions() {
            this.partitionsOverviewState = PartitionsOverviewState.LOADING;

            try {
                this.partitions = await partitionsApi.list();
            } finally {
                this.partitionsOverviewState = PartitionsOverviewState.READY;
            }
        },

        async toggleAddDialog() {
            if (this.partitionCreatorState === PartitionCreatorState.VISIBLE) {
                this.partitionCreatorState = PartitionCreatorState.HIDDEN;
            } else {
                this.partitionCreatorState = PartitionCreatorState.LOADING;
                try {
                    this.mysqlDatabases = (await mysqlDatabasesApi.getAll())
                        .filter(
                            (mysqlDatabase) =>
                                !!mysqlDatabase.mysqlDatabaseSummary.host &&
                                mysqlDatabase.usageCount < MAX_PARTITIONS_PER_DATABASE
                        )
                        .sort((a, b) => a.usageCount - b.usageCount);
                    this.elasticClusters = (await elasticClustersApi.list())
                        .filter((elasticCluster) => elasticCluster.usageCount < MAX_PARTITIONS_PER_ELASTIC_CLUSTER)
                        .sort((a, b) => a.usageCount - b.usageCount);
                    this.loadbalancers = (await loadbalancersApi.list())
                        .filter(
                            (loadbalancer) =>
                                !!loadbalancer.host && loadbalancer.usageCount < MAX_PARTITIONS_PER_LOADBALANCER
                        )
                        .sort((a, b) => a.usageCount - b.usageCount);
                } finally {
                    this.partitionCreatorState = PartitionCreatorState.HIDDEN;
                }

                if (!this.mysqlDatabases.length) {
                    showInfo(
                        this.$t(
                            "Es wurde keine Datenbank gefunden. Bitte fügen Sie zunächst eine MySQL Datenbank hinzu und fahren Sie dann fort."
                        ) as string
                    );
                } else if (!this.elasticClusters.length) {
                    showInfo(
                        this.$t(
                            "Es wurde kein Elastic-Cluster gefunden. Bitte fügen Sie zunächst einen Elastic-Cluster hinzu und fahren Sie dann fort."
                        ) as string
                    );
                } else if (!this.loadbalancers.length) {
                    showInfo(
                        this.$t(
                            "Es wurde kein Loadbalancer gefunden. Bitte fügen Sie zunächst einen Loadbalancer hinzu und fahren Sie dann fort."
                        ) as string
                    );
                } else {
                    this.partitionId = "";
                    this.partitionName = "";
                    this.machineType = PartitionMachineType.M;
                    this.loadbalancer = this.loadbalancers[0].id;
                    this.database = this.mysqlDatabases[0].mysqlDatabaseSummary.id;
                    this.elasticClusterId = this.elasticClusters[0].id;
                    this.dealerdeskTwilioUseMainAccount = false;
                    (this.$refs.form as any).resetValidation();
                    this.partitionCreatorState = PartitionCreatorState.VISIBLE;
                }
            }
        },

        async createPartition() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.partitionCreatorState = PartitionCreatorState.CREATING;
            try {
                await partitionsApi.add({
                    id: this.partitionId,
                    name: this.partitionName,
                    loadbalancerId: this.loadbalancer,
                    mysqlDatabaseId: this.database,
                    elasticClusterId: this.elasticClusterId,
                    machineType: this.machineType,
                    dealerdeskTwilioUseMainAccount: this.dealerdeskTwilioUseMainAccount,
                    prefix: this.prefix,
                    sequenceOffset: this.sequenceOffset,
                    country: this.country,
                    currency: this.currency,
                    marketingEmailsEnabled: this.marketingEmailsEnabled,
                });
            } catch (e) {
                this.partitionCreatorState = PartitionCreatorState.VISIBLE;
                if (!(e instanceof BadRequest)) {
                    throw e;
                }
                this.validationHelper.update(e, this.$refs.form);
                return;
            }
            this.partitionCreatorState = PartitionCreatorState.HIDDEN;
            await this.loadPartitions();
        },

        async recreateInstances(renewMysqlPassword: boolean) {
            if (
                !(await showConfirm(
                    this.$t("Partitionen neustarten") as string,
                    this.$t("Sind Sie sicher, dass Sie alle Partitionen neustarten möchten?") as string
                ))
            ) {
                return;
            }

            this.recreatingInstances = true;
            try {
                await partitionsApi.recreateInstances(renewMysqlPassword);
            } finally {
                this.recreatingInstances = false;
            }
        },
    },

    async mounted() {
        await this.loadPartitions();
    },
});
